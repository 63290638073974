/* eslint-disable no-shadow */

export enum ROLES {
  User = "User",
  Admin = "Admin"
}

export enum ORG_TYPES {
  client = "Client",
  provider = "Provider"
}
