/* eslint-disable no-throw-literal */
export default function jwtDecode(jwt: string) {
  function b64DecodeUnicode(str: string) {
    return decodeURIComponent(
      atob(str).replace(/(.)/g, (m, p) => {
        let code = p.charCodeAt(0).toString(16).toUpperCase();
        if (code.length < 2) {
          code = `0${code}`;
        }
        return `%${code}`;
      })
    );
  }

  function decode(str: string) {
    let output = str.replace(/-/g, "+").replace(/_/g, "/");
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += "==";
        break;
      case 3:
        output += "=";
        break;
      default:
        throw "Illegal base64url string!";
    }

    try {
      return b64DecodeUnicode(output);
    } catch (err) {
      return atob(output);
    }
  }

  const jwtArray = jwt.split(".");

  return {
    header: decode(jwtArray[0]),
    payload: decode(jwtArray[1]),
    signature: decode(jwtArray[2])
  };
}
