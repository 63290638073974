import React from "react";
import "./navigationBar.scss";
import { useNavigate } from "react-router-dom";
import NavBar from "../designSystem/navBar/navBar";
import VARIANT from "../designSystem/variant";
import Logo from "../logo/logo";
import Button from "../designSystem/button/button";
import Icon from "../designSystem/icon/icon";
import {
  URL_ORGANIZATIONS,
  URL_SCAN,
  URL_USERS,
  URL_SETTINGS,
  URL_INSPECTORS
} from "../constants/appUrls";
import {
  INSPECTORS,
  ORGANIZATIONS,
  SCAN,
  SETTINGS,
  USERS
} from "../constants/images";
import usePermissions from "../permissions/usePermissions";
import PERMISSIONS_LIST from "../permissions/permissions";
import Tooltip from "../designSystem/tooltip/tooltip";
import AvatarMenu from "./avatarMenu/avatarMenu";

export default function NavigationBar() {
  const navigate = useNavigate();
  const canI = usePermissions();

  return (
    <NavBar logo={<Logo />}>
      {canI(PERMISSIONS_LIST.seeMSPPages) && (
        <div className="has-tooltip">
          <Button
            onClick={() => navigate(URL_ORGANIZATIONS)}
            variant={VARIANT.secondary}
          >
            <Icon alt="Organizations" image={ORGANIZATIONS} />
          </Button>
          <Tooltip text="Organizations" />
        </div>
      )}
      <div className="has-tooltip">
        <Button onClick={() => navigate(URL_SCAN)} variant={VARIANT.secondary}>
          <Icon alt="Scan" image={SCAN} />
        </Button>
        <Tooltip text="Scan" />
      </div>
      {canI(PERMISSIONS_LIST.seeUsers) && (
        <div className="has-tooltip">
          <Button
            onClick={() => navigate(URL_USERS)}
            variant={VARIANT.secondary}
          >
            <Icon alt="Users" image={USERS} />
          </Button>
          <Tooltip text="Users" />
        </div>
      )}
      {canI(PERMISSIONS_LIST.seeSettings) && (
        <div className="has-tooltip">
          <Button
            onClick={() => navigate(URL_SETTINGS)}
            variant={VARIANT.secondary}
          >
            <Icon alt="Settings" image={SETTINGS} />
          </Button>
          <Tooltip text="Settings" />
        </div>
      )}
      {canI(PERMISSIONS_LIST.seeInspectors) && (
        <div className="has-tooltip">
          <Button
            onClick={() => navigate(URL_INSPECTORS)}
            variant={VARIANT.secondary}
          >
            <Icon alt="Inspectors" image={INSPECTORS} />
          </Button>
          <Tooltip text="Inspectors" />
        </div>
      )}
      <AvatarMenu />
    </NavBar>
  );
}
