/* eslint-disable no-alert */
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useUsersAPI from "../api/useUsersAPI";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setRole } from "../redux/session.slice";
import { setRoles } from "../redux/roles.slice";
import { rolesMapper } from "../users/users-list.mappers";
import { INVITATION_ROLE_FLAG } from "../constants/localStorage";
import jwtDecode from "../utils/jwt.utils";
import { URL_LOGOUT } from "../constants/appUrls";

export function RolesProvider({ children }: any) {
  const roleAsignationRef = useRef(false);

  const navigate = useNavigate();
  const { accessToken, roleId, userId, email, onboardingCompleted } =
    useAppSelector((state) => state.session);

  const { getRoles, getUserRolesByEmail, assignRoleByInvitation } =
    useUsersAPI();
  const dispatch = useAppDispatch();
  const rolesRef = useRef(false);

  const retrieveRoles = useCallback(async () => {
    try {
      const roles = await getRoles();
      dispatch(setRoles(rolesMapper(roles)));
      const role = JSON.parse(jwtDecode(accessToken).payload)["user/roles"][0];
      dispatch(
        setRole({
          role: role.name,
          id: role.id
        })
      );
    } catch (e) {
      navigate(URL_LOGOUT);
      console.error(`error: ${e}`);
    }
  }, [accessToken, dispatch, getRoles, navigate]);

  const assignUserRole = useCallback(async () => {
    try {
      const currentRoles = await getUserRolesByEmail(email);
      if (!currentRoles.length) {
        await assignRoleByInvitation(userId, roleId);
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  }, [assignRoleByInvitation, email, getUserRolesByEmail, roleId, userId]);

  useEffect(() => {
    if (
      !roleAsignationRef.current &&
      userId &&
      roleId &&
      email &&
      localStorage.getItem(INVITATION_ROLE_FLAG) === "true"
    ) {
      roleAsignationRef.current = true;
      localStorage.removeItem(INVITATION_ROLE_FLAG);
      assignUserRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, roleId, email]);

  useEffect(() => {
    if (!rolesRef.current && accessToken && onboardingCompleted) {
      rolesRef.current = true;
      retrieveRoles();
    }
  }, [accessToken, retrieveRoles, onboardingCompleted]);

  return children;
}

export default RolesProvider;
