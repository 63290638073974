import { apiResponseFormatter } from "../utils/object.utils";
import { apiRequest, useOptions } from "./api";
import API_METHODS from "./apiMethods";

export default function useOrganizationsAPI() {
  const { getOptions, setBody, setMethod } = useOptions();
  const { setEndpoint, run } = apiRequest();
  const apiUrl: string = process.env.REACT_APP_API_ORG!;
  const apiVersion = "/v1";
  const apiPrefix = `${apiUrl}${apiVersion}/organizations`;

  const getTenantFromExternalServiceProvidersByEmail = async (
    email: string
  ) => {
    setEndpoint(`${apiUrl}/external_service_providers/by_email/${email}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const organization = await run(options);
    return apiResponseFormatter(organization);
  };

  const getOrganizationByEmail = async (email: string) => {
    setEndpoint(`${apiPrefix}/by_email/${email}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const organization = await run(options);
    return apiResponseFormatter(organization);
  };

  const getOrganizationFromExternalServiceProviderByEmail = async (
    email: string
  ) => {
    setEndpoint(`${apiPrefix}/external_service_provider/by_email/${email}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const organization = await run(options);
    return apiResponseFormatter(organization);
  };

  const getOrganizationFromExternalServiceProviderByTenantId = async (
    tenantId: string
  ) => {
    setEndpoint(
      `${apiPrefix}/by_external_service_provider/organization/${tenantId}`
    );
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const organization = await run(options);
    return apiResponseFormatter(organization);
  };

  const getOrganizationDataFromExternalServiceProviderByTenantId = async (
    tenantId: string
  ) => {
    setEndpoint(`${apiPrefix}/by_external_service_provider/tenant/${tenantId}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const organization = await run(options);
    return apiResponseFormatter(organization);
  };

  const addOrganizationByExternalProvider = async (organization: any) => {
    setEndpoint(`${apiPrefix}/by_external_provider`);
    setMethod(API_METHODS.POST);
    setBody(organization);
    const options = getOptions();
    const organizationResponse = await run(options);
    return apiResponseFormatter(organizationResponse);
  };

  const setOrganizationStatus = async (status: any) => {
    setEndpoint(`${apiPrefix}/status`);
    setMethod(API_METHODS.PATCH);
    setBody(status);
    const options = getOptions();
    const statusResponse = await run(options);
    return apiResponseFormatter(statusResponse);
  };

  const setOrganizationStatusFromExternalProvider = async (status: any) => {
    setEndpoint(`${apiPrefix}/external_service_provider/status`);
    setMethod(API_METHODS.PATCH);
    setBody(status);
    const options = getOptions();
    const statusResponse = await run(options);
    return apiResponseFormatter(statusResponse);
  };

  const getOrganizationsByParentId = async (orgId: string) => {
    setEndpoint(`${apiPrefix}/by_parent_organization/${orgId}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const organizations = await run(options);
    return apiResponseFormatter(organizations);
  };

  return {
    addOrganizationByExternalProvider,
    getOrganizationByEmail,
    getOrganizationFromExternalServiceProviderByEmail,
    getOrganizationFromExternalServiceProviderByTenantId,
    getOrganizationDataFromExternalServiceProviderByTenantId,
    getTenantFromExternalServiceProvidersByEmail,
    setOrganizationStatus,
    setOrganizationStatusFromExternalProvider,
    getOrganizationsByParentId
  };
}
