import React, { useMemo } from "react";
import "./avatarMenu.scss";
import { useNavigate } from "react-router-dom";
import { URL_LOGOUT, URL_PROFILE } from "../../constants/appUrls";
import { useAppSelector } from "../../redux/hooks";
import Menu, { IMenuItem } from "../../designSystem/menu/menu";
import { LOGOUT, SETTINGS_WHITE } from "../../constants/images";
import { buildUrl } from "../../utils/string.utils";
import Avatar from "../../designSystem/avatar/avatar";

export default function AvatarMenu() {
  const { userName, organizationName, userImage } = useAppSelector(
    (state: any) => state.session
  );

  const menuItems: IMenuItem[] = useMemo(
    () => [
      {
        id: "avatar",
        value: userName || "",
        subValue: organizationName,
        avatarImage: userImage
      },
      { id: "__divider" },
      { id: "profile", value: "User profile", icon: SETTINGS_WHITE },
      { id: "__divider" },
      { id: "logOut", value: "Log out", icon: LOGOUT }
    ],
    [userName, organizationName, userImage]
  );

  const navigate = useNavigate();

  const actions: { [key: string]: any } = useMemo(
    () => ({
      avatar: () => navigate(buildUrl(URL_PROFILE)),
      profile: () => navigate(buildUrl(URL_PROFILE)),
      logOut: () => navigate(URL_LOGOUT)
    }),
    [navigate]
  );

  const onMenuItemSelected = (menuItem: string) => actions[menuItem]();

  return (
    <nav className="avatarMenuContainer">
      <Menu items={menuItems} clickAction={onMenuItemSelected} left>
        <Avatar image={userImage} />
      </Menu>
    </nav>
  );
}
