import apiResponseFormatter from "../utils/object.utils";
import { apiRequest, useOptions } from "./api";
import API_METHODS from "./apiMethods";

export default function useUsersAPI() {
  const { getOptions, setMethod } = useOptions();
  const { setEndpoint, run } = apiRequest();
  const apiUrl: string = process.env.REACT_APP_API_USERS!;
  const apiVersion = "";
  const apiPrefix = `${apiUrl}${apiVersion}/users`;

  const getProfileFromExternalServiceProviderByEmail = async (
    email: string
  ) => {
    setEndpoint(
      `${apiUrl}/external_service_providers/user_profile/by_email/${email}`
    );
    setMethod(API_METHODS.GET);
    const options = getOptions();
    // Object returned by MS
    const profile = await run(options);
    return profile;
  };

  const getProfileFromExternalServiceProviderByOrganization = async (
    organizationId: string
  ) => {
    setEndpoint(
      `${apiPrefix}/organization/by_identity_provider/${organizationId}`
    );
    setMethod(API_METHODS.GET);
    const options = getOptions();
    // Object returned by MS
    const profile = await run(options);
    return profile;
  };

  const addUserToOrganization = async (
    email: string,
    organizationId: string
  ) => {
    setEndpoint(
      `${apiPrefix}/by_email/${email}/organization/${organizationId}`
    );
    setMethod(API_METHODS.POST);
    const options = getOptions();
    // Object returned by Auth0
    const identityProviderUser = await run(options);
    return identityProviderUser;
  };

  const getUserData = async (userId: string) => {
    setEndpoint(`${apiPrefix}/by_identity_user_id/${userId}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const userData = await run(options);
    return apiResponseFormatter(userData);
  };

  const getUsersList = async (orgId: string) => {
    setEndpoint(`${apiPrefix}/by_identity_provider/${orgId}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const usersList = await run(options);
    return apiResponseFormatter(usersList);
  };

  const getRoles = async () => {
    setEndpoint(`${apiUrl}/roles`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const roles = await run(options);
    return apiResponseFormatter(roles);
  };
  const getUserRolesByEmail = async (userEmail: string) => {
    setEndpoint(`${apiPrefix}/roles/by_email/${userEmail}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const roles = await run(options);
    return apiResponseFormatter(roles);
  };

  const assignRole = async (
    userId: string,
    roleId: string,
    oldRoleId: string
  ) => {
    setEndpoint(
      `${apiPrefix}/${userId}/role/${roleId}?old_identity_role_id=${oldRoleId}`
    );
    setMethod(API_METHODS.PATCH);
    const options = getOptions();
    const result = await run(options);
    return apiResponseFormatter(result);
  };

  const assignRoleByInvitation = async (userId: string, roleId: string) => {
    setEndpoint(`${apiPrefix}/${userId}/invitation/role/${roleId}`);
    setMethod(API_METHODS.PATCH);
    const options = getOptions();
    const result = await run(options);
    return apiResponseFormatter(result);
  };

  const deleteUser = async (userId: string) => {
    setEndpoint(`${apiPrefix}/${userId}`);
    setMethod(API_METHODS.DELETE);
    const options = getOptions();
    const result = await run(options);
    return apiResponseFormatter(result);
  };

  return {
    addUserToOrganization,
    getProfileFromExternalServiceProviderByEmail,
    getProfileFromExternalServiceProviderByOrganization,
    getUsersList,
    deleteUser,
    getRoles,
    assignRoleByInvitation,
    assignRole,
    getUserRolesByEmail,
    getUserData
  };
}
