/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from "react";
import "./tabs.scss";

type ITabsProps = { children: any; currentTab?: string };

// eslint-disable-next-line react/no-unused-prop-types
type ITabProps = { children: any; title: string };

type ITabNavItemProps = {
  id: string;
  title: string;
  activeTab: string;
  setActiveTab: any;
};

type ITabContentProps = {
  id: string;
  activeTab: string;
  children: any;
};

function TabNavItem({ id, title, activeTab, setActiveTab }: ITabNavItemProps) {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <li onClick={handleClick} className={activeTab === id ? "active" : ""}>
      {title}
    </li>
  );
}

function TabContent({ id, activeTab, children }: ITabContentProps) {
  return activeTab === id ? (
    <div className="Tab-content">{children}</div>
  ) : null;
}

export function Tab({ children }: ITabProps) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export function Tabs({ children, currentTab }: ITabsProps) {
  const [activeTab, setActiveTab] = useState<string>("tab-0");
  const items = useMemo(
    () =>
      children.filter(
        ({ props }: any) => props && Object.hasOwn(props, "title")
      ),
    [children]
  );

  useEffect(() => {
    if (currentTab) setActiveTab(currentTab);
  }, [currentTab]);

  return (
    <div className="tabs">
      <ul className="tabs-nav">
        {items.map((item: any, index: number) => (
          <TabNavItem
            key={`tab-${index}`}
            title={item.props.title}
            id={`tab-${index}`}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        ))}
      </ul>

      <div className="tabs-outlet">
        {items.map((item: any, index: number) => (
          <TabContent
            key={`tab-${index}`}
            id={`tab-${index}`}
            activeTab={activeTab}
          >
            {item.props.children}
          </TabContent>
        ))}
      </div>
    </div>
  );
}

export default Tabs;
