import { IOrganization } from "../../onboarding/onBoarding.types";
import { IOrganizationList } from "../types/organizations.types";

export default function organizationsListMapper(
  organizationsList: IOrganization[]
): IOrganizationList {
  const result: IOrganizationList = {};
  organizationsList.forEach((organization: IOrganization) => {
    result[organization.id] = {
      ...organization,
      lastScan: {
        ...organization.lastScan,
        createdAt: organization.lastScan?.summary.findings
          ? organization.lastScan.createdAt
          : "",
        summary: {
          ...organization.lastScan?.summary,
          severity: {
            critical: organization.lastScan?.summary.severity?.critical || 0,
            high: organization.lastScan?.summary.severity?.high || 0,
            medium: organization.lastScan?.summary.severity?.medium || 0,
            low: organization.lastScan?.summary.severity?.low || 0,
            informational:
              organization.lastScan?.summary.severity?.informational || 0
          },
          status: {
            new: organization.lastScan?.summary.status?.new || 0,
            unchanged: organization.lastScan?.summary.status?.unchanged || 0,
            modified: organization.lastScan?.summary.status?.modified || 0,
            remediated: organization.lastScan?.summary.status?.remediated || 0
          }
        }
      }
    } as IOrganization;
  });
  return result;
}
