import React, { MouseEvent } from "react";
import "./icon.scss";

type IconProps = {
  alt?: string;
  disabled?: boolean;
  href?: string;
  image: string;
  right?: boolean;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
};

export default function Icon(props: IconProps) {
  const { right, onClick, image, alt = "", disabled, href } = props;
  const classRoot = "iconContainer";

  let className = classRoot;
  className += `${right ? ` ${classRoot}--right` : ""}${
    disabled ? " disabled" : ""
  }`;

  const onClickAction = (event: MouseEvent<HTMLImageElement>) =>
    !disabled && onClick && onClick(event);

  if (href) {
    return (
      <a target="_blank" href={href} rel="noreferrer">
        <img className={className} src={image} alt={alt} />
      </a>
    );
  }

  return (
    <img className={className} src={image} alt={alt} onClick={onClickAction} />
  );
}
