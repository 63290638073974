import { createSlice } from "@reduxjs/toolkit";

type IRole = {
  id: string;
  name: string;
  description: string;
};

export type RolesState = {
  [key: string]: IRole[];
};

const initialState: RolesState = {};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setRoles: (state, action) => {
      Object.keys(action.payload).forEach((role: any) => {
        state[role] = action.payload[role];
      });
    }
  }
});

export const { setRoles } = rolesSlice.actions;
export default rolesSlice.reducer;
