import { IAnyPropertyNameAndAnyValue } from "../constants/common.types";
import { propNameformatter } from "./string.utils";

export const apiResponseFormatter = (
  object: IAnyPropertyNameAndAnyValue
): any => {
  if (object === null) return object;
  const isArray = Array.isArray(object);
  const result: any = isArray ? [] : {};
  if (isArray) {
    object.forEach((prop) => {
      result.push(typeof prop === "object" ? apiResponseFormatter(prop) : prop);
    });
  } else {
    Object.keys(object).forEach((prop) => {
      result[propNameformatter(prop)] =
        typeof object[prop] === "object"
          ? apiResponseFormatter(object[prop])
          : object[prop];
    });
  }

  return result;
};

export default apiResponseFormatter;
