/* eslint-disable no-alert */
import React from "react";
import { SnackbarProvider } from "./snackbar.context";
import { ModalProvider } from "./modal.context";
import { OrganizationsProvider } from "./organizations.context";
import RolesProvider from "./roles.context";
import { FeatureFlagsProvider } from "./feature-flags.provider";

export function CommonProvider({ children }: any) {
  return (
    <FeatureFlagsProvider>
      <SnackbarProvider>
        <ModalProvider>
          <RolesProvider>
            <OrganizationsProvider>{children}</OrganizationsProvider>
          </RolesProvider>
        </ModalProvider>
      </SnackbarProvider>
    </FeatureFlagsProvider>
  );
}

export default CommonProvider;
