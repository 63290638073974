import React, { useCallback, useEffect, useState, useContext } from "react";
import Loader from "../loader/loader";
import "./snackbar.scss";
import { ISnackbar, ISnackbarData } from "./snackbar.types";
import { ISnackbarContext } from "../../contexts/types/snackbar.types";
import SnackbarContext from "../../contexts/snackbar.context";

function Snackbar({
  data: {
    text,
    onClose,
    alt,
    type,
    icon,
    content,
    loadingSnackbar,
    key,
    timeout
  },
  killSnackbar
}: ISnackbar) {
  const [fade, setFade] = useState(true);

  const onDestroy = useCallback(() => {
    onClose();
    killSnackbar(key);
  }, [killSnackbar, onClose, key]);

  useEffect(() => {
    const fadeOut = setTimeout(() => setFade(true), timeout - 1000);
    const fadeIn = setTimeout(() => setFade(false), 200);
    return () => {
      clearTimeout(fadeIn);
      clearTimeout(fadeOut);
    };
  }, [timeout]);

  return (
    <div className={`snackbarContainer ${type}${fade ? " fade" : ""}`}>
      <div className="snackbarContent" onClick={onDestroy}>
        {loadingSnackbar && (
          <div className="loadingSnackbar">
            <Loader />
          </div>
        )}
        {icon && <img className="snackbarContent__icon" src={icon} alt={alt} />}
        <p className="snackbarContent__text">{text}</p>
        <div className="contentContainer">{content}</div>
      </div>
    </div>
  );
}

export default function SnackbarList() {
  const { snackbarQueue, killSnackbar }: ISnackbarContext =
    useContext(SnackbarContext);

  return (
    <section className="snackbarListContainer">
      {snackbarQueue &&
        Object.values(snackbarQueue).map((data: ISnackbarData) => (
          <Snackbar key={data.key} data={data} killSnackbar={killSnackbar} />
        ))}
    </section>
  );
}
