import { createSlice } from "@reduxjs/toolkit";
import SERVICE_PROVIDERS from "../login/serviceProviders";
import { ORG_TYPES } from "../constants/roles";

export type Tenant = {
  id: string;
  displayName: string;
  identityUserEmail: string;
  parentOrganizationId: string;
};

export type SessionState = {
  role: string;
  roleId: string;
  accessToken: string;
  email: string;
  userId: string;
  userName: string;
  userImage: string;
  loggedIn: boolean;
  onboardingCompleted: boolean;
  organizationName: string;
  organizationId: string;
  identityOrgId: string;
  organizationType: ORG_TYPES;
  organizationsList: object | null;
  serviceProvider: SERVICE_PROVIDERS;
  tenant: Tenant;
};

const initialState: SessionState = {
  role: "",
  roleId: "",
  email: "",
  userId: "",
  userName: "",
  userImage: "",
  loggedIn: false,
  onboardingCompleted: false,
  organizationName: "",
  organizationId: "",
  identityOrgId: "",
  organizationType: ORG_TYPES.client,
  serviceProvider: SERVICE_PROVIDERS.NONE,
  tenant: {
    id: "",
    displayName: "",
    identityUserEmail: "",
    parentOrganizationId: ""
  },
  accessToken: "",
  organizationsList: null
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    logIn: (state) => {
      state.loggedIn = true;
    },
    logOut: (state) => {
      state.role = "";
      state.roleId = "";
      state.email = "";
      state.userId = "";
      state.userName = "";
      state.userImage = "";
      state.loggedIn = false;
      state.onboardingCompleted = false;
      state.organizationName = "";
      state.organizationId = "";
      state.identityOrgId = "";
      state.organizationType = ORG_TYPES.client;
      state.serviceProvider = SERVICE_PROVIDERS.NONE;
      state.tenant = {
        id: "",
        displayName: "",
        identityUserEmail: "",
        parentOrganizationId: ""
      };
      state.accessToken = "";
      state.organizationsList = null;
    },
    setRole: (state, action) => {
      state.role = action.payload.role;
      state.roleId = action.payload.id;
    },
    setOnboardingCompleted: (state) => {
      state.onboardingCompleted = true;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setUserImage: (state, action) => {
      state.userImage = action.payload;
    },
    setOrganizationName: (state, action) => {
      state.organizationName = action.payload;
    },
    setOrganizationId: (state, action) => {
      state.organizationId = action.payload;
    },
    setIdentityOrgId: (state, action) => {
      state.identityOrgId = action.payload;
    },
    setServiceProvider: (state, action) => {
      state.serviceProvider = action.payload;
    },
    setTenant: (state, action) => {
      state.tenant = action.payload;
    },
    setOrganizationType: (state, action) => {
      state.organizationType = action.payload;
    },
    setOrganizationsList: (state, action) => {
      state.organizationsList = action.payload;
    }
  }
});

export const {
  logIn,
  logOut,
  setAccessToken,
  setEmail,
  setOrganizationId,
  setIdentityOrgId,
  setServiceProvider,
  setTenant,
  setOnboardingCompleted,
  setRole,
  setOrganizationType,
  setUserId,
  setUserImage,
  setUserName,
  setOrganizationName,
  setOrganizationsList
} = sessionSlice.actions;
export default sessionSlice.reducer;
