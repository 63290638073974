import React, {
  useCallback,
  createContext,
  useState,
  useMemo,
  useRef,
  useEffect,
  useContext
} from "react";
import { SortingState } from "@tanstack/react-table";
import FeatureFlagContext from "./feature-flags.provider";
import { IProvider, IState } from "../constants/common.types";
import {
  IOrganizationList,
  IOrganizationsContext
} from "./types/organizations.types";
import useOrganizationsListModel from "./models/organizations.model";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ORG_TYPES } from "../constants/roles";

import { setOrganizationsList as setOrganizationsListInRedux } from "../redux/session.slice";

const OrganizationsContext = createContext<IOrganizationsContext>(
  {} as IOrganizationsContext
);

export function OrganizationsProvider({ children }: IProvider) {
  const dispatch = useAppDispatch();
  const { getOrganizationsList, getOwnOrganization } =
    useOrganizationsListModel();
  const [organizationsList, setOrganizationsList] = useState<IOrganizationList>(
    {}
  );

  const { feature_frameworks_mapping } = useContext(FeatureFlagContext);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [
    organizationListPersistedSorting,
    setOrganizationListPersistedSorting
  ] = useState<SortingState>([]);
  const loadingRef = useRef(false);

  const { organizationType, email, organizationId } = useAppSelector(
    (state: IState) => state.session
  );

  const loadOrganizationsList = useCallback(async () => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setLoading(true);
    const service = {
      [ORG_TYPES.provider]: getOrganizationsList,
      [ORG_TYPES.client]: getOwnOrganization
    };
    const data: IOrganizationList = service[organizationType]
      ? await service[organizationType]()
      : {};
    setLoading(false);
    loadingRef.current = false;
    setDataLoaded(true);
    setOrganizationsList(data);
    // avoid to populate redux state with an object with same organization in it's organizationsList
    if (organizationType === ORG_TYPES.provider) {
      dispatch(setOrganizationsListInRedux(data));
    }
  }, [getOrganizationsList, getOwnOrganization, organizationType, dispatch]);

  useEffect(() => {
    organizationType && email && organizationId && loadOrganizationsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationType, email, organizationId]);

  const returnedInformation: IOrganizationsContext = useMemo(
    () => ({
      loading,
      organizationsList,
      dataLoaded,
      organizationListPersistedSorting,
      setOrganizationListPersistedSorting,
      featureFlags: { feature_frameworks_mapping }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dataLoaded,
      loading,
      organizationListPersistedSorting,
      organizationsList,
      feature_frameworks_mapping
    ]
  );

  return (
    <OrganizationsContext.Provider value={returnedInformation}>
      {children}
    </OrganizationsContext.Provider>
  );
}

export default OrganizationsContext;
