const IMAGE_CDN = process.env.REACT_APP_CDN_IMAGES;

export const CHECK = `${IMAGE_CDN}/check.svg`;
export const CHEVRON_DOWN = `${IMAGE_CDN}/chevronDown.svg`;
export const CHEVRON_RIGHT = `${IMAGE_CDN}/chevronRight.svg`;
export const DIVIDER = `${IMAGE_CDN}/divider.svg`;
export const DOWNLOAD = `${IMAGE_CDN}/download.svg`;
export const FILTER = `${IMAGE_CDN}/filter.svg`;
export const LOADING = `${IMAGE_CDN}/loadingAnimation.svg`;
export const LOGO = `${IMAGE_CDN}/inspectLogo.svg`;
export const LOGOUT = `${IMAGE_CDN}/logOut.svg`;
export const NO_FINDINGS = `${IMAGE_CDN}/noFindings.svg`;
export const OFFICE_365_AGREEMENT_SERVICE_PRINCIPAL = `${IMAGE_CDN}/hotlink-ok/office365Agreement_ServicePrincipal.png`;
export const SCAN = `${IMAGE_CDN}/scan.svg`;
export const SEARCH = `${IMAGE_CDN}/search.svg`;
export const SETTINGS = `${IMAGE_CDN}/settingsBlack.svg`;
export const SETTINGS_WHITE = `${IMAGE_CDN}/settings.svg`;
export const WARNING_CIRCLE = `${IMAGE_CDN}/warningCircle.svg`;
export const ORGANIZATIONS = `${IMAGE_CDN}/organizations.svg`;
export const SUCCESS = `${IMAGE_CDN}/success.svg`;
export const ERROR = `${IMAGE_CDN}/error.svg`;
export const AVATAR_ADD = `${IMAGE_CDN}/avatarAdd.svg`;
export const MORE = `${IMAGE_CDN}/more.svg`;
export const EDIT = `${IMAGE_CDN}/edit.svg`;
export const DELETE = `${IMAGE_CDN}/delete.svg`;
export const USERS = `${IMAGE_CDN}/users.svg`;
export const USER = `${IMAGE_CDN}/user.svg`;
export const ADD = `${IMAGE_CDN}/add.svg`;
export const RESUME = `${IMAGE_CDN}/play.svg`;
export const PAUSED = `${IMAGE_CDN}/pause.svg`;
export const INFORMATION = `${IMAGE_CDN}/information.svg`;
export const INSPECTORS = `${IMAGE_CDN}/inspectors.svg`;
