import {
  configureStore,
  MiddlewareArray,
  PreloadedState
} from "@reduxjs/toolkit";
import LogRocket from "logrocket";
import sessionReducer from "./session.slice";
import RolesReducer from "./roles.slice";

export const STORAGE_KEY = "soteria-inspect-state";
const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STORAGE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error: any) {
    console.log("Error loading state from storage.", error.message);
    return undefined;
  }
};

const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(STORAGE_KEY, serializedState);
  } catch (error: any) {
    console.log("Error saving state to storage.", error.message);
  }
};

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    roles: RolesReducer
  },
  preloadedState: loadState(),
  middleware: new MiddlewareArray().concat(LogRocket.reduxMiddleware())
});

export type RootState = ReturnType<typeof store.getState>;

// This is only used by the tests (unit, integration) that will require access to Redux state
export const setupTestStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: {
      session: sessionReducer
    },
    preloadedState
  });
export type AppTestStore = ReturnType<typeof setupTestStore>;

store.subscribe(() => {
  saveState(store.getState());
});

export type AppDispatch = typeof store.dispatch;
