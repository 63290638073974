import React from "react";
import "./logo.scss";
import { LOGO } from "../constants/images";

export default function Logo() {
  return (
    <div className="logo">
      <img src={LOGO} alt="Soteria Inspect" />
    </div>
  );
}
