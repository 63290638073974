export const URL_ACTIVATION = "/activation"; // URL where Microsoft redirect the app after authentication is successful via MSAL
export const URL_ACTIVATION_PENDING = "/activation-pending";
export const URL_INVALID_ONBOARDING_STAGE = "/invalid-onboarding-stage";
export const URL_LOGIN_ERROR = "/loginError";
export const URL_LOGIN_PERMISSIONS_ERROR = "/loginPermissionsError";
export const URL_LOGOUT = "/logout";
export const URL_OFFICE_365_ACTIVATION = "/office365activation";
export const URL_OFFICE_365_ROLES = "/office365roles";
export const URL_ORGANIZATIONS = "/organizations";
export const URL_USERS = "/users";
export const URL_USERS_MSP = "/organization/:orgId/users";
export const URL_USERS_DETAILS = "/users/:userId";
export const URL_USERS_MSP_DETAILS = "/organization/:orgId/users/:userId";
export const URL_ROOT = "/";
export const URL_SCAN = "/scan";
export const URL_SCAN_MSP = "/organization/:orgId/scan";
export const URL_FINDING = "/scan/:scanId/finding/:findingId";
export const URL_FINDING_MSP = `/organization/:orgId/scan/:scanId/finding/:findingId`;
export const URL_SETTINGS = "/settings";
export const URL_SETTINGS_MSP = "/organization/:orgId/settings";
export const URL_INSPECTORS = "/inspectors";
export const URL_INSPECTORS_MSP = "/organization/:orgId/inspectors";
export const URL_PROFILE = "/profile";
export const URL_SUBSCRIPTION = "/subscription";
export const URL_SUBSCRIPTION_CONFIRMED = "/subscription-confirmed";
export const URL_SUBSCRIPTION_PENDING = "/subscriptionPending";
export const URL_TERMS_DECLINED = "/termsDeclined";
export const URL_UNAUTHORIZED = "/unauthorized";
export const URL_AUTHORIZE = "/authorize";
export const URL_NOT_FOUND = "/not-found";

export const NO_NAV_BAR_URLS = [
  URL_LOGOUT,
  URL_ROOT,
  URL_ACTIVATION,
  URL_OFFICE_365_ROLES,
  URL_SUBSCRIPTION,
  URL_SUBSCRIPTION_PENDING
];
