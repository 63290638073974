import React, { ReactNode } from "react";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { AUTH0_APP_KEYS } from "./auth0AppKeys";

type auth0ProviderProps = { children: ReactNode };
export default function Auth0ProviderWithHistory({
  children
}: auth0ProviderProps) {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={AUTH0_APP_KEYS.DOMAIN}
      clientId={AUTH0_APP_KEYS.CLIENT_ID}
      authorizationParams={{
        audience: AUTH0_APP_KEYS.AUDIENCE,
        redirect_uri: AUTH0_APP_KEYS.CALLBACK_URL
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}
