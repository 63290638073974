import { useContext } from "react";
import useOrganizationsAPI from "../../api/useOrganizationsAPI";
import { useAppSelector } from "../../redux/hooks";
import SnackbarContext from "../snackbar.context";

import { IOrganization } from "../../onboarding/onBoarding.types";
import { MESSAGE } from "../../designSystem/snackbar/snackbar.types";
import { ERROR } from "../../constants/images";
import organizationsListMapper from "../mappers/organizations-list.mappers";
import { IOrganizationList } from "../types/organizations.types";

export default function useOrganizationsListModel() {
  const { organizationId, email } = useAppSelector(
    (state: any) => state.session
  );

  const { getOrganizationsByParentId, getOrganizationByEmail } =
    useOrganizationsAPI();
  const { showSnackbar } = useContext(SnackbarContext);

  const getOrganizationsList = async (): Promise<IOrganizationList> => {
    try {
      const organizationList: IOrganization[] =
        await getOrganizationsByParentId(organizationId);
      return organizationsListMapper(organizationList);
    } catch (error: any) {
      console.error(
        `Error getting organization list for organization ${organizationId}. ${error}. Cause ${error.cause}`
      );
      showSnackbar({
        text: `Error retrieving organizations list`,
        type: MESSAGE.error,
        icon: ERROR
      });
    }
    return {};
  };

  const getOwnOrganization = async (): Promise<IOrganizationList> => {
    try {
      const organization: IOrganization = await getOrganizationByEmail(email);
      return organizationsListMapper([organization]);
    } catch (error: any) {
      console.error(
        `Error getting organization data of ${organizationId}. ${error}. Cause ${error.cause}`
      );
      showSnackbar({
        text: `Error retrieving organization data`,
        type: MESSAGE.error,
        icon: ERROR
      });
    }
    return {};
  };

  return { getOrganizationsList, getOwnOrganization };
}
