export type ISnackbarData = {
  text: string;
  onClose: () => any;
  alt: string;
  type: string;
  icon: string;
  content: any;
  loadingSnackbar: boolean;
  key: number;
  timeout?: any;
};

export type IShowSnackbar = {
  text?: string;
  onClose?: () => any;
  alt?: string;
  type?: string;
  icon?: string;
  content?: any;
  loadingSnackbar?: boolean;
  key?: number;
  timeout?: any;
};

export type ISnackbar = {
  data: ISnackbarData;
  killSnackbar: (id: number) => void;
};

// eslint-disable-next-line no-shadow
export enum MESSAGE {
  info = "info",
  error = "error",
  warning = "warning"
}
