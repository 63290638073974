import { IAnyPropertyNameAndAnyValue, IRole } from "../constants/common.types";
import { ROLES } from "../constants/roles";
import { IMenuItem } from "../designSystem/menu/menu";
import { IUserData } from "./users.types";

export const usersListMapper = (usersList: IUserData[]): IUserData[] =>
  usersList.map((user: IUserData) => ({ ...user, id: user.userId! }));

export const rolesSelectMapper = (
  roles: IAnyPropertyNameAndAnyValue
): IMenuItem[] => {
  const result: IMenuItem[] = [];
  Object.keys(roles).forEach((key: string) =>
    result.push({ id: key, value: roles[key].name })
  );
  return result;
};

export const rolesMapper = (roles: IRole[]): IAnyPropertyNameAndAnyValue => {
  const result: IAnyPropertyNameAndAnyValue = {};
  roles.forEach((role: any) => {
    result[role.id] = role;
  });
  return result;
};

export const rolesFirstUserMapper = (
  roles: { [key: string]: IRole },
  firstUser: boolean
): IAnyPropertyNameAndAnyValue =>
  rolesMapper(
    Object.values(roles).filter(
      (role: IRole) => !firstUser || role.name === ROLES.Admin
    )
  );

export default rolesSelectMapper;
